/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import UserService from "@/api/user-service";

const state = {
    users: [],
    activeUsers: [],
    levels: [],
    user: {},
    allRoles: [],
    allDisabledUsers: [],
    userCredentials: "",
};
const mutations = {
    storeUsers(currentState: any, data: any): void {
        currentState.users = data;
    },
    storeActiveUsers(currentState: any, data: any): void {
        currentState.activeUsers = data;
    },
    storeUser(currentState: any, data: any): void {
        currentState.user = { ...data };
    },
    storeLevels(currentState: any, data: any): void {
        currentState.levels = data;
    },
    disabledUsers(currentState: any, data: any): void {
        currentState.allDisabledUsers = data;
    },
    SET_ALL_ROLES(currentState: any, data: any) {
        currentState.allRoles = data;
    },
    SET_USER_CREDENTIALS(currentState: any, data: any) {
        currentState.userCredentials = data;
    },
};
const actions = {
    /**
     * Create User
     * @param _
     * @param payload
     */
    async createUser(_: any, payload: any): Promise<void> {
        const { data } = await UserService.createUser(payload);
        return data;
    },
    async sendCredentials({ commit }: any, id: any): Promise<void> {
        const { data } = await UserService.sendUserCredentials(id);
        commit("SET_USER_CREDENTIALS", data);
        return data;
    },

    /**
     * Create Role
     * @param _
     * @param payload
     */
    async createRole(_: any, payload: any): Promise<void> {
        const { data } = await UserService.createRole(payload);
        return data;
    },

    /**
     * Fetch Single User
     * @param commit
     * @param userId
     */
    async fetchSingleUser({ commit }: any, userId: string): Promise<void> {
        const { data } = await UserService.fetchSingleUser(userId);
        commit("storeUser", data);
    },
    async fetchDisabledUsers({ commit }: any, status: string): Promise<void> {
        const { data } = await UserService.fetchDisabledUsers(status);
        commit("disabledUsers", data);
    },

    /**
     * Delete Single User
     * @param commit
     * @param userId
     */
    async deleteSingleUser({ commit }: any, userId: string): Promise<void> {
        const { data } = await UserService.deleteSingleUser(userId);
        return data;
    },
    async enableUser({ commit }: any, userId: string): Promise<void> {
        const { data } = await UserService.enableUser(userId);
        return data;
    },

    /**
     * Update Single User
     * @param commit
     * @param userId
     * @param body
     */
    async updateSingleUser({ commit }: any, { userId, body }: { userId: string; body: any }): Promise<void> {
        const { data } = await UserService.updateSingleUser({ userId, body });
        commit("storeUser", data);
    },

    /**
     * Update Single User Roles
     * @param commit
     * @param userId
     * @param body
     */
    async updateSingleUserRoles({ commit }: any, { userId, body }: { userId: string; body: any }): Promise<void> {
        const { data } = await UserService.updateSingleUserRoles({
            userId,
            body,
        });
        return data;
    },

    /**
     * Fetch Active Users
     * @param commit
     */
    async fetchActiveUsers({ commit }: any): Promise<void> {
        const { data } = await UserService.fetchActiveUsers();
        commit("storeActiveUsers", data);
    },
    async fetchUsers({ commit }: any, role = ""): Promise<void> {
        const { data } = await UserService.fetchUsers(role);
        // await dispatch("fetchLevels");
        commit("storeUsers", data);
    },
    async fetchLevels({ commit }: any): Promise<void> {
        const { data } = await UserService.fetchLevels();
        commit("storeLevels", data);
    },
    async fetchRoles({ commit }: any): Promise<void> {
        const { data } = await UserService.fetchRoles();
        commit("SET_ALL_ROLES", data);
    },
};

const getters = {
    allUsers: ({ users }: { users: any }) => users,
    activeUsers: ({ activeUsers }: { activeUsers: any }) => activeUsers,
    user: ({ user }: { user: any }) => user,
    levels: ({ levels }: { levels: any }) => levels,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
