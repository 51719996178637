/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import AuthService from "@/api/auth-service";

const userStored = localStorage.getItem("user");

const state = {
    userObj: userStored !== "undefined" ? JSON.parse(userStored as string) : {},
};
const mutations = {
    storeUser(currentState: any, data: any): void {
        currentState.userObj = data;
        localStorage.setItem("user", JSON.stringify(data));
    },
    UPDATE_SIGNATURE_IMAGEID(currentState: any, signatureImageId: string): void {
        console.log("We got into the store mutation >>>>>>", signatureImageId);
        currentState.userObj.signatureImageId = signatureImageId;
    },
};
const actions = {
    async login({ dispatch }: any, form: any): Promise<unknown> {
        const { userName, password } = form;

        const res = await AuthService.loginUser({
            userName,
            password,
        });

        if (res.status === 200) {
            AuthService.authenticateUser({
                access: res.data?.token,
            });

            await dispatch("fetchProfile");
        }

        return res;
    },
    async signUp(_: any, form: any): Promise<unknown> {
        const res = await AuthService.signUpUser(form);
        return res;
    },
    async verifyEmail(_: any, form: any): Promise<unknown> {
        const res = await AuthService.verifyEmail(form);
        return res;
    },
    async postActivation(_: any, form: any): Promise<unknown> {
        const res = await AuthService.resendActivation(form);
        return res;
    },
    async passwordForgot(_: any, form: any): Promise<unknown> {
        const res = await AuthService.forgotPassword(form);
        return res;
    },
    async passwordReset(_: any, form: any): Promise<unknown> {
        const res = await AuthService.resetPassword(form);
        return res;
    },
    async fetchProfile({ commit }: any): Promise<void> {
        const { data } = await AuthService.fetchUserProfile();
        commit("storeUser", { ...data });
        // await dispatch("fetchClaims");
    },
    async fetchClaims({ commit }: any): Promise<void> {
        const { data } = await AuthService.fetchClaims();
        commit("storeUser", { ...state.userObj, ...data });
    },
    async updateProfile(_: any, payload: any): Promise<void> {
        const res = await AuthService.updateUserProfile(payload);
        // commit("storeUser", data);
        return res;
    },
    async changePassword(_: any, form: any): Promise<unknown> {
        const res = await AuthService.changePassword(form);
        return res;
    },
    updateSignatureImageId({ commit }: any, signatureImageId: string): void {
        commit("UPDATE_SIGNATURE_IMAGEID", signatureImageId);
    },
};
const getters = {
    getUserData({ userObj }: any) {
        if (userObj) {
            return userObj;
        }

        return null;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
