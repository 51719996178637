/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

import CompanyService from "@/api/company";
import resolveRequestError from "@/utils/errorHandler";

const state = {
    profile: {},
    positions: [],
};

const mutations = {
    storeProfile(currentState: any, data: any): void {
        currentState.profile = { ...data };
    },
    storePositions(currentState: any, data: any): void {
        currentState.positions = [...data];
    },
};
const actions = {
    /**
     * Fetch Company Positions Action
     * @param commit
     */
    async fetchPositions({ commit }: any): Promise<void> {
        try {
            const { data } = await CompanyService.fetchPositions();
            commit("storePositions", data);
            return data;
        } catch (error) {
            throw resolveRequestError(error);
        }
    },

    /**
     * Fetch Company Profile Action
     * @param commit
     */
    async fetchProfile({ commit }: any, id: any): Promise<void> {
        try {
            const { data } = await CompanyService.fetchProfile(id);
            commit("storeProfile", data);
            return data;
        } catch (error) {
            throw resolveRequestError(error);
        }
    },

    /**
     * Update Company Profile Action
     * @param _
     * @param payload
     */
    async updateProfile(_: any, payload: any): Promise<void> {
        try {
            const res = await CompanyService.updateProfile(payload);
            return res;
        } catch (error) {
            throw resolveRequestError(error);
        }
    },
};
const getters = {
    getProfile: ({ profile }: any): any => profile,
    getPositions: ({ positions }: any): any => positions,
    companyPositionsText: ({ positions }: any): Array<string> => {
        const [KeyPositions, VariablePositions] = positions;
        if (KeyPositions && VariablePositions) {
            return [
                ...KeyPositions.positions,
                ...VariablePositions.positions,
            ].reduce((prev, current) => {
                return [...prev, current.name];
            }, []);
        }
        return [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
