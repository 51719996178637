/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import ReportService from "@/api/report-service";

const state = {
    reports: [],
    report: {},
    reportDetails: {},
};
const mutations = {
    storeReports(currentState: any, data: any): void {
        currentState.reports = data;
    },
    storeReport(currentState: any, data: any): void {
        currentState.report = { ...data };
    },
    removeReport(currentState: any, reportId: string): void {
        const reportIndex = currentState.reports?.findIndex((rep: any) => rep.id === reportId);

        currentState.reports.splice(reportIndex, 1);
    },
};
const actions = {
    async fetchReportList({ commit }: any, variables: any): Promise<void> {
        const { data } = await ReportService.fetchReportList(variables);
        commit("storeReports", data);
        return data;
    },

    async fetchReportListOnUserDesk(_: any, variables: any): Promise<void> {
        const { data } = await ReportService.fetchReportListOnUserDesk(variables);
        return data;
    },

    async fetchReportById({ commit }: any, id: string): Promise<void> {
        const { data } = await ReportService.fetchReportById(id);
        commit("storeReport", data);
        return data;
    },

    // async fetchReportDetails({ commit }: any, payload: string): Promise<void> {
    //     const { data } = await ReportService.fetchReportDetails(payload);
    //     console.log("from the store", data);
    // },

    async makeCreator(_: any, payload: Array<string>): Promise<void> {
        const { data } = await ReportService.makeCreator(payload);
        // commit("storeUsers", data);
        return data;
    },

    async makeReviewer(_: any, payload: Array<string>): Promise<void> {
        const { data } = await ReportService.makeReviewer(payload);
        // commit("storeUsers", data);
        return data;
    },

    async makeSignatory(_: any, payload: Array<string>): Promise<void> {
        const { data } = await ReportService.makeSignatory(payload);
        // commit("storeUsers", data);
        return data;
    },

    async startReview(_: any, payload: string): Promise<void> {
        const { data } = await ReportService.startReview(payload);
        return data;
    },
};

const getters = {
    getReport: ({ report }: any): any => report,
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
