import { DateTime } from "luxon";
// eslint-disable-next-line import/no-extraneous-dependencies
import camelCase from "lodash/camelCase";
// eslint-disable-next-line import/no-extraneous-dependencies
import mapKeys from "lodash/mapKeys";

export const formatDate = (date: string, format = DateTime.DATE_FULL): string => {
    if (!date) {
        return "";
    }

    const dateTime = DateTime.fromISO(date, { zone: "utc" });

    return dateTime.toLocaleString(format);
};

export const timeAgo = (date: any) => {
    const seconds = Math.floor(((new Date() as any) - date) / 1000);

    let interval = seconds / 31536000;

    if (interval > 1) {
        return `${Math.floor(interval)} year${Math.floor(interval) > 1 ? "s" : ""} ago`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return `${Math.floor(interval)} month${Math.floor(interval) > 1 ? "s" : ""} ago`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return `${Math.floor(interval)} day${Math.floor(interval) > 1 ? "s" : ""} ago`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return `${Math.floor(interval)} hour${Math.floor(interval) > 1 ? "s" : ""} ago`;
    }
    interval = seconds / 60;
    if (interval > 1) {
        return `${Math.floor(interval)} minute${Math.floor(interval) > 1 ? "s" : ""} ago`;
    }
    return `${Math.floor(interval)} second${Math.floor(interval) > 1 ? "s" : ""} ago`;
};

export const formatDateToLocale = (date: string, format = "DDD"): string => {
    if (!date) {
        return "";
    }

    const dateTime = DateTime.fromISO(date);

    return dateTime.toFormat(format);
};

/* eslint-disable prefer-template */
export const parseJwt = (token: string): any => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
    );

    return mapKeys(JSON.parse(jsonPayload), (v, k) => camelCase(k));
};

/**
 * Formats money
 * @param {String} amount
 * @param {Boolean} addSign
 */
/* eslint-disable no-param-reassign */
export const formatMoney = (number = 0, countryCode = "NG", currencyCode = "NGN") => {
    if (Number.isNaN(parseFloat(countryCode))) {
        countryCode = "NG";
    }
    if (Number.isNaN(parseFloat(currencyCode))) {
        currencyCode = "NGN";
    }
    const formatter = new Intl.NumberFormat(`en-${countryCode}`, {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 2,
    });
    return formatter.format(number);
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const formatFileSize = (bytes: number, si = false, dp = 1): string => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        // eslint-disable-next-line no-param-reassign
        bytes /= thresh;
        // eslint-disable-next-line no-plusplus
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return `${bytes.toFixed(dp)} ${units[u]}`;
};

export const groupBy = (xs: any[], key: string) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const romanize = (num: number): string | number => {
    if (Number.isNaN(num)) return NaN;
    const digits = String(+num).split("");
    const key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM", "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC", "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"];
    let roman = "";
    let i = 3;
    // eslint-disable-next-line no-plusplus
    while (i--) roman = (key[+(digits as any[]).pop() + i * 10] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
};

export const testFunc = (): string => "Rahhh";

export const fetchYearsTillDate = (startYear: number): Array<number> => {
    const currentYear = new Date().getFullYear();
    const years = <number[]>[];
    // eslint-disable-next-line no-plusplus
    for (let i = currentYear; i >= startYear; i--) {
        years.push(i);
    }
    return years;
};
